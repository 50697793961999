import React, { useEffect, useState, useCallback, useRef } from "react";
import { CustomButton, CustomButtonProps } from "../CustomButton";
import {
    HeaderWrapper,
    TranscriberInputWrapper,
    ButtonWrapper,
    RecordingsWrapper,
    Recording,
    RecordingHeader,
    Transcript,
    EditButton,
} from "./index.styles";
import { IoIosArrowDown } from "react-icons/io";
import EditTranscriptionPopup from "./EditTranscriptionPopup";
import styled, { keyframes, css } from "styled-components";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
`;

interface RecordButtonProps extends CustomButtonProps {
    isRecording: boolean;
}

const RecordButton = styled(CustomButton)<RecordButtonProps>`
    ${(props) =>
        props.isRecording &&
        css`
            background-color: red !important;
            animation: ${pulse} 2s infinite;
        `}
`;

interface TranscriberInputProps {
    onRecordingStop?: (audioUrl: string) => void;
    onTranscriptionChange?: (newTranscription: string[]) => void;
    onProcess?: () => void;
    transcriptions?: string[];
    output: Record<string, any> | null;
    licensePlate: string;
    onDeleteTranscription?: (index: number) => void;
    onEditTranscription?: (index: number, newTranscription: string) => void;
}

export const TranscriberInput: React.FC<TranscriberInputProps> = ({
    onRecordingStop,
    onTranscriptionChange,
    onProcess,
    transcriptions = [],
    output,
    licensePlate,
    onDeleteTranscription,
    onEditTranscription,
}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
        null
    );
    const [audioURL, setAudioURL] = useState("");
    const [visibleNotes, setVisibleNotes] = useState<{
        [key: number]: boolean;
    }>({});
    const [editingTranscription, setEditingTranscription] = useState<
        number | null
    >(null);
    const [recordingTime, setRecordingTime] = useState(0);
    const [initialDeviceId, setInitialDeviceId] = useState<string | null>(
        null
    );
    const [currentDeviceId, setCurrentDeviceId] = useState<string | null>(
        null
    );
    const audioChunks = useRef<Blob[]>([]);
    const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [audioContext, setAudioContext] = useState<AudioContext | null>(
        null
    );
    const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
    const volumeIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const lowVolumeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [finalBlob, setFinalBlob] = useState<Blob | null>(null);

    useEffect(() => {
        navigator.mediaDevices.addEventListener(
            "devicechange",
            handleDeviceChange
        );
        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                handleDeviceChange
            );
        };
    }, []);

    const handleDeviceChange = async () => {
        const newDevice = await getCurrentAudioDevice();
        if (newDevice) {
            setCurrentDeviceId(newDevice.deviceId);
            if (isRecording) {
                if (newDevice.deviceId !== initialDeviceId) {
                    console.log("Switched to a different device");
                    pauseRecording();
                } else {
                    console.log("Switched back to initial device");
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: { deviceId: { exact: initialDeviceId } },
                    });
                    setupMediaRecorder(stream);
                    resumeRecording();
                }
            }
        }
    };

    const getCurrentAudioDevice = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.find((device) => device.kind === "audioinput");
    };

    const setupMediaRecorder = (stream: MediaStream) => {
        const mimeType = "audio/webm;codecs=opus";
        const newMediaRecorder = new MediaRecorder(stream, { mimeType });

        newMediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                audioChunks.current.push(e.data);
            }
        };

        setMediaRecorder(newMediaRecorder);

        // Set up audio context and analyser
        const newAudioContext = new AudioContext();
        const newAnalyser = newAudioContext.createAnalyser();
        const source = newAudioContext.createMediaStreamSource(stream);
        source.connect(newAnalyser);

        setAudioContext(newAudioContext);
        setAnalyser(newAnalyser);
    };
    const startVolumeLogging = () => {
        if (analyser) {
            const dataArray = new Uint8Array(analyser.frequencyBinCount);
            const logVolume = () => {
                analyser.getByteFrequencyData(dataArray);
                const average =
                    dataArray.reduce((sum, value) => sum + value, 0) /
                    dataArray.length;
                const volume = Math.round((average / 255) * 100);

                if (volume < 3) {
                    if (mediaRecorder && mediaRecorder.state === "recording") {
                        if (lowVolumeTimeoutRef.current === null) {
                            lowVolumeTimeoutRef.current = setTimeout(() => {
                                if (
                                    mediaRecorder &&
                                    mediaRecorder.state === "recording"
                                ) {
                                    mediaRecorder.pause();
                                }
                            }, 200); // Wait for 1 second of low volume before pausing
                        }
                    }
                } else {
                    if (lowVolumeTimeoutRef.current) {
                        clearTimeout(lowVolumeTimeoutRef.current);
                        lowVolumeTimeoutRef.current = null;
                    }
                    if (mediaRecorder && mediaRecorder.state === "paused") {
                        if (
                            mediaRecorder &&
                            mediaRecorder.state === "paused"
                        ) {
                            mediaRecorder.resume();
                        }
                    }
                }
            };

            // Log volume every 100ms (10 times per second)
            volumeIntervalRef.current = setInterval(logVolume, 100);
        }
    };

    const stopVolumeLogging = () => {
        if (volumeIntervalRef.current) {
            clearInterval(volumeIntervalRef.current);
            volumeIntervalRef.current = null;
        }
    };

    const initializeRecording = async () => {
        const currentDevice = await getCurrentAudioDevice();
        if (currentDevice) {
            setInitialDeviceId(currentDevice.deviceId);
            setCurrentDeviceId(currentDevice.deviceId);
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: currentDevice.deviceId } },
            });
            setupMediaRecorder(stream);
        }
    };

    useEffect(() => {
        initializeRecording();
    }, []);

    const startRecording = () => {
        if (mediaRecorder && !isRecording) {
            setIsRecording(true);
            audioChunks.current = [];
            mediaRecorder.start(1000); // Collect data every second
            startRecordingTimer();
            startVolumeLogging();
        }
    };

    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            stopRecordingTimer();
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording && mediaRecorder.state === "paused") {
            mediaRecorder.resume();
            startRecordingTimer();
        }
    };

    const stopRecording = () => {
        if (mediaRecorder && isRecording) {
            setIsRecording(false);
            setIsPaused(false);
            mediaRecorder.stop();
            stopRecordingTimer();
            createFinalAudioBlob();
            stopVolumeLogging();
            if (lowVolumeTimeoutRef.current) {
                clearTimeout(lowVolumeTimeoutRef.current);
                lowVolumeTimeoutRef.current = null;
            }
        }
    };

    const createFinalAudioBlob = () => {
        const newFinalBlob = new Blob(audioChunks.current, {
            type: "audio/webm;codecs=opus",
        });
        setFinalBlob(newFinalBlob);
        const audioSrc = URL.createObjectURL(newFinalBlob);
        setAudioURL(audioSrc);
        if (onRecordingStop) {
            onRecordingStop(audioSrc);
        }
    };

    const downloadAudioBlob = () => {
        if (finalBlob) {
            const url = URL.createObjectURL(finalBlob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `audio_recording_${new Date().toISOString()}.webm`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    const startRecordingTimer = () => {
        if (!recordingIntervalRef.current) {
            recordingIntervalRef.current = setInterval(() => {
                setRecordingTime((prevTime) => prevTime + 1);
            }, 1000);
        }
    };

    const stopRecordingTimer = () => {
        if (recordingIntervalRef.current) {
            clearInterval(recordingIntervalRef.current);
            recordingIntervalRef.current = null;
            setRecordingTime(0);
        }
    };

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${
            remainingSeconds < 10 ? "0" : ""
        }${remainingSeconds}`;
    };

    const toggleNoteVisibility = (index: number) => {
        setVisibleNotes((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleEditClick = (index: number) => {
        setEditingTranscription(index);
    };

    const handleSaveEdit = (newTranscription: string) => {
        if (editingTranscription !== null && onEditTranscription) {
            onEditTranscription(editingTranscription, newTranscription);
            setEditingTranscription(null);
        }
    };

    const handleCloseEdit = () => {
        setEditingTranscription(null);
    };

    return (
        <TranscriberInputWrapper>
            <HeaderWrapper>
                <h3>Jouw Notities</h3>
                <ButtonWrapper>
                    <RecordButton
                        disabled={false}
                        text={
                            isRecording
                                ? `Stop opname (${formatTime(recordingTime)})`
                                : "Opnemen"
                        }
                        iconName={
                            isRecording ? "FaRegStopCircle" : "FaMicrophone"
                        }
                        onClick={isRecording ? stopRecording : startRecording}
                        isRecording={isRecording}
                    />
                    <CustomButton
                        disabled={isRecording}
                        text="Verstuur"
                        iconName="FaFileAlt"
                        onClick={() => onProcess?.()}
                    />
                    <CustomButton
                        disabled={!finalBlob}
                        text="Download Audio"
                        iconName="FaDownload"
                        onClick={downloadAudioBlob}
                    />
                </ButtonWrapper>
            </HeaderWrapper>

            <RecordingsWrapper>
                {transcriptions.map((transcription, index) => (
                    <Recording key={index}>
                        <RecordingHeader
                            onClick={() => toggleNoteVisibility(index)}
                        >
                            <IoIosArrowDown />
                            <h4 style={{ width: "100%", marginLeft: "10px" }}>
                                {"Transcript " + String(index + 1)}
                            </h4>
                            <EditButton onClick={() => handleEditClick(index)}>
                                Edit
                            </EditButton>
                            <CustomButton
                                backgroundColor="#ff4d4f"
                                onClick={() => onDeleteTranscription?.(index)}
                                iconName="FaTrash"
                            />
                        </RecordingHeader>
                        {visibleNotes[index] && (
                            <Transcript>{transcription}</Transcript>
                        )}
                    </Recording>
                ))}
            </RecordingsWrapper>
            {editingTranscription !== null && (
                <EditTranscriptionPopup
                    transcription={transcriptions[editingTranscription]}
                    onSave={handleSaveEdit}
                    onClose={handleCloseEdit}
                />
            )}
        </TranscriberInputWrapper>
    );
};

import styled from "styled-components";

export const StyledSelect = styled.select`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #ddd;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    color: #8a95a3;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 400;
    transition: all 0.3s ease-out;

    &:hover {
        cursor: pointer;
        background-color: #e6e6e6;
    }

    &:focus {
        outline: none;
        border-color: #0056b3;
    }
`;

import styled from "styled-components";

export const TextAreaComponent = styled.textarea`
    font-family: "Roboto", sans-serif;
    background-color: #fff;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #ddd;
    color: #000;
    outline: 0;
    padding: 15px 20px;
    text-align: left;
    color: #8a95a3;
    border-radius: 3px;
    vertical-align: top;
    resize: none;
    width: 100%;
`;

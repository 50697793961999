import { CustomButton } from "@components/CustomButton";
import { FormField } from "@components/FormField";
import React from "react";
import { authenticate } from "../../utils/authentication.utils";
import { useAuth } from "../../context/AuthContext";
import { redirect, useNavigate } from "react-router-dom";

export const Login: React.FC = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const data = await authenticate(username, password);
            if (data.access_token) {
                login();
                navigate("/");
            }
        } catch (error) {
            alert(error);
            console.error("Authentication error: ", error);
        }
    };

    return (
        <div className="">
            <FormField
                type="text"
                label="Gebruikersnaam"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <FormField
                type="password"
                label="Wachtwoord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <CustomButton
                text="Login"
                iconName="FaSignInAlt"
                onClick={handleLogin}
                style={{ width: "100%", margin: 0 }}
            />
        </div>
    );
};

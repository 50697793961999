import { TextAreaComponent } from "./index.styles";

interface TextAreaProps {
    placeholder?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
export const TextArea: React.FC<TextAreaProps> = (props) => {
    return <TextAreaComponent {...props} />;
};

import styled from "styled-components";

const CustomButtonComponent = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    color: white;
    outline: none;
    border: none;
    padding: 12px 20px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    color: #fff;
    display: inline-block;
    border-radius: 0;
    background: #053b80;
    background-color: rgb(5, 59, 128);
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    transition: all 0.3s ease-out;
    font-size: 1.6rem;
    line-height: 1;
    text-shadow: none;
    font-weight: 600;
    border: 0;
    letter-spacing: 0.5px;
`;

export { CustomButtonComponent };

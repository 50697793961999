import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import styled from "styled-components";

const LayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0px;
`;

const ContentWrapper = styled.div`
    flex: 1;
    overflow: auto;
`;

export const Layout: React.FC = () => {
    const [licensePlate, setLicensePlate] = useState<string | undefined>(
        undefined
    );

    return (
        <LayoutWrapper>
            <Navigation licensePlate={licensePlate} />
            <ContentWrapper>
                <Outlet context={{ setLicensePlate }} />
            </ContentWrapper>
        </LayoutWrapper>
    );
};

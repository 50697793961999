import styled from "styled-components";

export const LicensePlateWrapper = styled.button`
    border-style: none;
    cursor: pointer;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: #f4be04;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 2rem;
    max-width: 200px;
`;

export const LicensePlate = styled.div`
    display: flex;
    align-items: center;
    background-color: #f4be04;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    color: black;
`;

export const JobSelectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 5px;
    height: 100%;
    min-width: 200px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 3rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px #ddd solid;
`;

export const NLTag = styled.div`
    width: 30px;
    height: 39px;
    background-color: #266ce9;
    color: white;
    align-items: end;
    display: flex;
    justify-content: center;
    padding: 5px 5px;
`;

export const LicenseNumber = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    width: 100%;
    font-weight: bold;
    padding: 10px;
    color: black;
`;

export const JobWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 1rem;
    border-bottom: 1px #ddd solid;
    cursor: pointer;

    &:hover {
        background-color: #e8e8e8;
    }
`;

export const JobListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`;

export const ChecklistType = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
    gap: 0;
    line-height: 1.2rem;
`;

export const ExpandButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-top: 0.25rem;
    color: #666;

    &:hover {
        color: #333;
    }
`;

import axios from "../includes/axios";

export const transcribeAudio = async (
    licensePlate: string,
    formData: FormData,
) => {
    try {
        const response = await axios.post(
            `/cars/${licensePlate}/transcribe`,
            formData,
        );
        return response.data;
    } catch (error) {
        console.error("Error transcribing audio:", error);
    }
};

export const processTranscriptions = async (
    licensePlate: string,
    transcriptions: string[],
) => {
    try {
        const response = await axios.post(`/cars/${licensePlate}/process`, {
            transcriptions,
            token: localStorage.getItem("access_token"),
        });
        // If code is 401, redirect to login
        if (response.status === 401) {
            localStorage.removeItem("access_token");
            window.location.href = "/login";
        }

        return response.data;
    } catch (error) {
        console.error("Error processing transcriptions:", error);
    }
};

export const deleteTranscription = async (
    licensePlate: string,
    index: number,
) => {
    try {
        const response = await axios.delete(
            `/cars/${licensePlate}/delete_transcription`,
            {
                data: { index },
            },
        );
        return response.data;
    } catch (error) {
        console.error("Error deleting transcription:", error);
    }
};

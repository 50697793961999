import axios from "../includes/axios";

export const checkAuth = async () => {
    const token = localStorage.getItem("access_token");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    if (!token || !username || !password) return false;

    try {
        const response = await axios.post("verify_token", { token });
        return true;
    } catch (error: any) {
        console.log("Token verification error: ", error);
        return false;
    }
};

export const authenticate = async (username: string, password: string) => {
    try {
        const response = await axios.post("/login", { username, password });
        if (response.data.access_token) {
            console.log(username, password);
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
        }
        return response.data;
    } catch (error: any) {
        throw error;
    }
};

import styled from "styled-components";

export const SubmissionCheckWrapper = styled.div`
    display: flex;
    border-radius: 5px;
    flex: 1;
    background: white;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const NotesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    overflow-y: auto;
    height: 100%;
    gap: 1.5rem;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.4rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px #ddd solid;
`;

export const Note = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: #f2f2f2;
    width: 100%;
    margin: 1rem 0;
`;

export const NoteDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const EditableNote = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: start;
`;

export const ConfirmButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    border: none;

    padding: 12px 20px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    color: #fff;
    display: inline-block;
    border-radius: 0;
    background: #053b80;
    background-color: rgb(5, 59, 128);
    transition: all 0.3s ease-out;
    font-size: 1.6rem;
    line-height: 1;
    text-shadow: none;
    font-weight: 400;
    border: 0;
    letter-spacing: 0.5px;
`;

export const EditButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    border: none;

    padding: 12px 20px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    color: #053b80;
    display: inline-block;
    border-radius: 0;
    background: #f2f2f2;
    transition: all 0.3s ease-out;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 400;
    text-shadow: none;
    border: 1px solid #053b80;
    letter-spacing: 0.5px;
`;

export const DotCircleWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 0;
`;

export const NoteContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PopupContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
`;

export const PopupButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0;
    gap: 1rem;
    margin-top: 1rem;
`;

export const CategoryWrapper = styled.div`
    margin-bottom: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;

    h3 {
        margin-bottom: 15px;
        color: #053b80;
    }

    h4 {
        margin: 15px 0 10px;
        color: #333;
    }
`;

export const AdviceWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
`;

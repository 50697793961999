import {
    Button,
    ButtonContainer,
    Overlay,
    Title,
    DialogWrapper,
} from "./index.styles";

type DialogProps = {
    message: string;
    title?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    confirmLabel?: string;
    cancelLabel?: string;
};

export const Dialog: React.FC<DialogProps> = ({
    message,
    title,
    onConfirm,
    onCancel,
    confirmLabel = "OK",
    cancelLabel = "Cancel",
}) => (
    <Overlay>
        <DialogWrapper>
            {title && <Title>{title}</Title>}
            <p>{message}</p>
            <ButtonContainer>
                <Button onClick={onConfirm}>{confirmLabel}</Button>
                {onCancel && <Button onClick={onCancel}>{cancelLabel}</Button>}
            </ButtonContainer>
        </DialogWrapper>
    </Overlay>
);

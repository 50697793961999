import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import {
    BackButton,
    ContentWrapper,
    MainWrapper,
    SideBySideContainer,
} from "./index.styles";
import { TranscriberInput } from "../../components/TranscriberInput";
import {
    getJob,
    submitFeedback,
    updateProcessedData,
    updateTranscription,
} from "../../utils/cars.utils";
import {
    transcribeAudio,
    processTranscriptions,
    deleteTranscription,
} from "../../utils/transcriptions.utils";
import { SubmissionCheck } from "@components/SubmissionCheck";
import { Dialog } from "../../components/Dialog";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { API_URL } from "@root/networking";
import SaveProgressPopup from "./SaveProgressPopup";
import FeedbackPopup from "./FeedbackPopup";

type Car = {
    jobHash: string;
    jobId: string;
    carId: string;
    jobName: string;
    carModel: string;
    licensePlate: string;
    checklistType: string;
    transcripts: string[];
};

type LayoutContext = {
    setLicensePlate: (licensePlate: string | undefined) => void;
};

export const Main: React.FC = () => {
    const { jobHash } = useParams<{ jobHash: string }>();
    const navigate = useNavigate();
    const [job, setJob] = useState<Record<string, any> | null>(null);
    const [confirming, setConfirming] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { setLicensePlate } = useOutletContext<LayoutContext>();
    const [saveProgress, setSaveProgress] = useState({
        open: false,
        step: 0,
        stepName: "",
        progress: "",
        error: "",
        alert: "",
        isCompleted: false,
    });
    const [showFeedbackForm, setShowFeedbackForm] = useState(false);

    const steps = [
        "Initializing",
        "Processing APK checks",
        "Processing tires",
        "Uploading to dealer portal",
        "Finalizing",
    ];

    useEffect(() => {
        const fetchJob = async () => {
            if (!jobHash) return;
            try {
                setLoading(true);
                const resp = await getJob(jobHash);
                setJob(resp);
                setLicensePlate(resp.carData.licensePlate);
            } catch (error) {
                console.error("Error fetching job:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchJob();

        return () => {
            setLicensePlate(undefined);
        };
    }, [jobHash, setLicensePlate]);

    const handleBackClick = () => {
        navigate("/");
    };

    const openDealerPortal = async () => {
        if (!jobHash) return;
        fetch(API_URL + "/cars/" + jobHash + "/naar_ewac", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(job),
        });

        const [selectedCarId, selectedJobId] = jobHash.split("-");

        window.open(
            "https://app.dealerportal.nl/inspection/" +
                selectedCarId +
                "/" +
                selectedJobId,
            "_blank"
        );
    };

    const saveReport = async () => {
        if (!jobHash) return;
        setSaveProgress({
            open: true,
            step: 0,
            stepName: "Preparing",
            progress: "",
            error: "",
            alert: "",
            isCompleted: false,
        });

        const updateProgress = (
            step: number,
            stepName: string,
            progress: string = "",
            error: string = "",
            alert: string = "",
            isCompleted: boolean = false
        ) => {
            setSaveProgress((prev) => ({
                ...prev,
                step,
                stepName,
                progress,
                error,
                alert,
                isCompleted,
            }));
        };

        try {
            const response = await fetch(`${API_URL}/cars/${jobHash}/save`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    job: job,
                    credentials: {
                        username: localStorage.getItem("username"),
                        password: localStorage.getItem("password"),
                    },
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const reader = response.body!.getReader();
            const decoder = new TextDecoder();

            let currentStep = 0;

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const decodedChunk = decoder.decode(value, { stream: true });
                const lines = decodedChunk.split("\n");

                for (const line of lines) {
                    if (line.trim() !== "") {
                        const data = JSON.parse(line);
                        if (data.step && data.stepName) {
                            while (currentStep < data.step - 1) {
                                currentStep++;
                                updateProgress(
                                    currentStep,
                                    steps[currentStep - 1]
                                );
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 500)
                                );
                            }
                            currentStep = data.step;
                            updateProgress(
                                data.step,
                                data.stepName,
                                data.progress || ""
                            );
                        } else if (data.message) {
                            updateProgress(
                                currentStep,
                                steps[currentStep - 1],
                                "",
                                "",
                                data.message,
                                true
                            );
                        } else if (data.error) {
                            throw new Error(data.error);
                        }
                    }
                }
            }

            if (currentStep < steps.length) {
                updateProgress(steps.length, steps[steps.length - 1]);
            }

            updateProgress(
                steps.length,
                steps[steps.length - 1],
                "",
                "",
                "Report saved successfully!",
                true
            );
        } catch (error) {
            console.error("Error:", error);
            updateProgress(
                0,
                "Error",
                "",
                error instanceof Error ? error.message : String(error),
                "",
                true
            );
        } finally {
            setShowFeedbackForm(true);
        }
    };

    const handleSaveProgressClose = () => {
        setSaveProgress((prev) => ({ ...prev, open: false }));
    };

    const handleFeedbackSubmit = async (rating: number, comment: string) => {
        setLoading(true);
        try {
            await submitFeedback(jobHash!, rating, comment);
            console.log(
                `Feedback submitted: Rating: ${rating}, Comment: ${comment}`
            );
        } catch (error) {
            console.error("Error submitting feedback:", error);
            // You might want to show an error message to the user here
        } finally {
            setLoading(false);
            setShowFeedbackForm(false);
        }
    };

    const handleFeedbackSkip = () => {
        setShowFeedbackForm(false);
    };

    const transcribe = async (audioUrl: string): Promise<void> => {
        if (!jobHash) return;
        try {
            setLoading(true);
            const audioBlob = await fetch(audioUrl).then((r) => r.blob());
            const formData = new FormData();
            formData.append("audio_file", audioBlob, "recording.webm");
            formData.append("license_plate", jobHash);

            const response = await fetch(
                `${API_URL}/cars/${jobHash}/transcribe`,
                {
                    method: "POST",
                    body: formData,
                }
            );

            if (!response.ok) throw new Error("Failed to transcribe audio");

            const data = await response.json();
            setJob(data);
        } catch (error) {
            console.error("Error transcribing audio:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = async () => {
        if (!jobHash) return;
        try {
            setConfirming(false);
            setLoading(true);
            const data = await processTranscriptions(
                jobHash,
                job?.carData.transcripts
            );

            if (data.message) {
                alert(data.message);
                return;
            }

            setJob((prevJob) => ({
                ...prevJob,
                carData: {
                    ...prevJob?.carData,
                    data: data.carData.data,
                    packages: data.carData.packages,
                },
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setConfirming(false);
    };

    const handleDeleteTranscription = async (index: number) => {
        if (!jobHash) return;
        try {
            setLoading(true);
            const data = await deleteTranscription(jobHash, index);
            setJob(data);
        } catch (error) {
            console.error("Error deleting transcription:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditTranscription = async (
        index: number,
        newTranscription: string
    ) => {
        if (!jobHash) return;
        try {
            setLoading(true);
            const updatedJob = await updateTranscription(
                jobHash,
                index,
                newTranscription
            );
            setJob(updatedJob);
        } catch (error) {
            console.error("Error editing transcription:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <MainWrapper>
            {loading && <LoadingAnimation />}
            <ContentWrapper>
                <BackButton onClick={handleBackClick}>{"< "}Terug</BackButton>
                <SideBySideContainer>
                    <TranscriberInput
                        onRecordingStop={transcribe}
                        transcriptions={job?.carData.transcripts || []}
                        onTranscriptionChange={(newTranscriptions) =>
                            setJob((prevJob) => ({
                                ...prevJob,
                                carData: {
                                    ...prevJob?.carData,
                                    transcripts: newTranscriptions,
                                },
                            }))
                        }
                        output={job}
                        onProcess={handleConfirm}
                        licensePlate={job?.carData.licensePlate || ""}
                        onDeleteTranscription={handleDeleteTranscription}
                        onEditTranscription={handleEditTranscription}
                    />
                    <SubmissionCheck
                        notes={job}
                        licensePlate={job?.carData.licensePlate || ""}
                        jobHash={jobHash || ""}
                        onDealerPortalClick={openDealerPortal}
                        onProcess={saveReport}
                        onNotesEdited={(newNotes) => setJob(newNotes)}
                    />
                </SideBySideContainer>
            </ContentWrapper>
            {confirming && (
                <Dialog
                    title="Are you sure you want to process these notes?"
                    message=""
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    confirmLabel="OK"
                    cancelLabel="Cancel"
                />
            )}
            <SaveProgressPopup
                open={saveProgress.open}
                step={saveProgress.step}
                stepName={saveProgress.stepName}
                progress={saveProgress.progress}
                error={saveProgress.error}
                alert={saveProgress.alert}
                isCompleted={saveProgress.isCompleted}
                onClose={handleSaveProgressClose}
            />
            {showFeedbackForm && (
                <FeedbackPopup
                    onSubmit={handleFeedbackSubmit}
                    onSkip={handleFeedbackSkip}
                />
            )}
        </MainWrapper>
    );
};

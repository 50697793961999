import { Wrapper, Label, Input } from "./index.style";

interface InputFieldProps {
    label: string;
    type?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}

export const FormField: React.FC<InputFieldProps> = ({
    label,
    type,
    onChange,
    value,
}) => {
    return (
        <Wrapper>
            <Label>{label}</Label>
            <Input type={type} onChange={onChange} value={value} />
        </Wrapper>
    );
};

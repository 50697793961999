import { CustomButtonComponent } from "./index.styles";
import * as Icons from "react-icons/fa";

export interface CustomButtonProps {
    backgroundColor?: string;
    text?: string;
    iconName: string;
    onClick?: () => void;
    onChange?: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
}

/**
 * CustomButton Component
 * @param {CustomButtonProps} props - Component properties
 * @returns {JSX.Element} - A custom button with text and an optional icon
 */
export const CustomButton: React.FC<CustomButtonProps> = (props) => {
    // Get icon component by its name
    const IconComponent = Icons[props.iconName as keyof typeof Icons];

    return (
        <CustomButtonComponent
            {...props}
            style={{ backgroundColor: props.backgroundColor, ...props.style }}
        >
            {props.text || null}
            {/* If such icon component exists, render it, otherwise render nothing */}
            {IconComponent ? (
                <IconComponent
                    style={{
                        marginLeft: props.text && 10,
                    }}
                />
            ) : null}
        </CustomButtonComponent>
    );
};

import "./App.css";
import "./index.css";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "./pages/";
import { Main } from "./pages/Main";
import { JobList } from "@pages/JobList";
import { Login } from "@pages/Login";
import { AuthProvider, useAuth } from "./context/AuthContext";

interface PrivateRouteProps {
    children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? (
        <>{children}</>
    ) : (
        <Navigate
            to="/login"
            replace
        />
    );
};

const App: React.FC = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/login"
                        element={<Login />}
                    />
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Layout />
                            </PrivateRoute>
                        }
                    >
                        <Route
                            index
                            element={<JobList />}
                        />
                        <Route
                            path="job/:jobHash"
                            element={<Main />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobSelection } from "../../components/JobSelection";
import { getCars } from "../../utils/cars.utils";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import styled from "styled-components";

type Car = {
    jobHash: string;
    jobId: string;
    carId: string;
    jobName: string;
    carModel: string;
    licensePlate: string;
    checklistType: string;
    transcripts: string[];
};

const FullScreenWrapper = styled.div`
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    padding: 32px;
    box-sizing: border-box;
    overflow: hidden;
    background: rgb(231, 233, 236);
`;

const JobListContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const JobList: React.FC = () => {
    const [cars, setCars] = useState<Car[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCars = async () => {
            try {
                const plates = await getCars();
                const fetchedCars: Car[] = plates.jobs.map((job: any) => ({
                    jobHash: job.car_id + "-" + job.job_id,
                    jobId: job.job_id,
                    carId: job.car_id,
                    jobName: job.job_name,
                    carModel: job.car_model,
                    licensePlate: job.license_plate,
                    checklistType: job.checklist_type,
                    transcripts: [],
                }));
                setCars(fetchedCars);
            } catch (error) {
                console.error("Error fetching cars:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCars();
    }, []);

    const handleJobSelect = async (jobHash: string): Promise<void> => {
        navigate(`/job/${jobHash}`);
    };

    if (loading) {
        return <LoadingAnimation />;
    }

    return (
        <FullScreenWrapper>
            <h1>Selecteer een werkorder</h1>
            <JobListContainer>
                <JobSelection
                    cars={cars}
                    selectJob={handleJobSelect}
                    selectedCar=""
                />
            </JobListContainer>
        </FullScreenWrapper>
    );
};

import React from "react";
import {
    NavBar,
    LeftSection,
    RightSection,
    Logo,
    LicensePlateDisplay,
} from "./index.styles";
import { Link } from "react-router-dom";
const logo = require("../../assets/img/Louwman-logo.png");
const adg_logo = require("../../assets/img/adggroep_logo.jpeg");

interface NavigationProps {
    licensePlate?: string;
}

export const Navigation: React.FC<NavigationProps> = ({ licensePlate }) => {
    return (
        <NavBar>
            <LeftSection>
                <Logo
                    src={logo}
                    alt="Louwman"
                />
                <Logo
                    src={adg_logo}
                    alt="Louwman"
                />
                {licensePlate && (
                    <LicensePlateDisplay>{licensePlate}</LicensePlateDisplay>
                )}
            </LeftSection>
            <RightSection>
                <Link
                    style={{
                        textDecoration: "none",
                        color: "black",
                        opacity: 0.4,
                    }}
                    onClick={() => {
                        localStorage.removeItem("access_token");
                    }}
                    to="/login"
                >
                    Uitloggen
                </Link>
            </RightSection>
        </NavBar>
    );
};

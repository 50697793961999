import React, { useState } from "react";
import { PopupOverlay, PopupContent, PopupButtons } from "./index.styles";
import { CustomButton } from "@components/CustomButton";
import { TextArea } from "@components/TextArea";
import styled from "styled-components";

const StarRating = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const Star = styled.span<{ filled: boolean }>`
    font-size: 24px;
    color: ${(props) => (props.filled ? "#ffc107" : "#e4e5e9")};
    cursor: pointer;
    margin: 0 5px;
`;

interface FeedbackPopupProps {
    onSubmit: (rating: number, comment: string) => void;
    onSkip: () => void;
}

const FeedbackPopup: React.FC<FeedbackPopupProps> = ({ onSubmit, onSkip }) => {
    const [rating, setRating] = useState<number>(0);
    const [comment, setComment] = useState<string>("");

    const handleSubmit = () => {
        onSubmit(rating, comment);
    };

    return (
        <PopupOverlay>
            <PopupContent>
                <h3>Feedback</h3>
                <p>
                    Beoordeel alstublieft uw ervaring en geef eventuele
                    aanvullende opmerkingen:
                </p>
                <StarRating>
                    {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                            key={star}
                            filled={star <= rating}
                            onClick={() => setRating(star)}
                        >
                            ★
                        </Star>
                    ))}
                </StarRating>
                <TextArea
                    value={comment}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setComment(e.target.value)
                    }
                    placeholder="Aanvullende opmerkingen (optioneel)"
                />
                <PopupButtons>
                    <CustomButton
                        text="Versturen"
                        iconName=""
                        onClick={handleSubmit}
                    />
                    <CustomButton
                        text="Overslaan"
                        iconName=""
                        onClick={onSkip}
                    />
                </PopupButtons>
            </PopupContent>
        </PopupOverlay>
    );
};

export default FeedbackPopup;

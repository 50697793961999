import React, { useState } from "react";
import {
    PopupWrapper,
    PopupContent,
    TextArea,
    ButtonWrapper,
} from "./EditTranscriptionPopup.styles";
import { CustomButton } from "../CustomButton";

interface EditTranscriptionPopupProps {
    transcription: string;
    onSave: (newTranscription: string) => void;
    onClose: () => void;
}

const EditTranscriptionPopup: React.FC<EditTranscriptionPopupProps> = ({
    transcription,
    onSave,
    onClose,
}) => {
    const [editedTranscription, setEditedTranscription] =
        useState(transcription);

    const handleSave = () => {
        onSave(editedTranscription);
    };

    return (
        <PopupWrapper>
            <PopupContent>
                <h3>Edit Transcription</h3>
                <TextArea
                    value={editedTranscription}
                    onChange={(e) => setEditedTranscription(e.target.value)}
                />
                <ButtonWrapper>
                    <CustomButton
                        text="Save"
                        onClick={handleSave}
                        iconName={""}
                    />
                    <CustomButton
                        text="Cancel"
                        onClick={onClose}
                        iconName={""}
                    />
                </ButtonWrapper>
            </PopupContent>
        </PopupWrapper>
    );
};

export default EditTranscriptionPopup;

import axios from "axios";

/**
 * Axios instance for making requests to the backend.
 * @type {AxiosInstance}
 */
const instance = axios.create({
    baseURL: "/api/", //change to http://localhost:5001/api for local development
    withCredentials: false,
    headers: {
        "Content-Type": "application/json",
    },
});

export default instance;

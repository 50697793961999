import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepConnector,
    stepConnectorClasses,
    LinearProgress,
    Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";

interface SaveProgressPopupProps {
    open: boolean;
    step: number;
    stepName: string;
    progress?: string;
    error?: string;
    alert?: string;
    onClose: () => void;
    isCompleted: boolean; // New prop to determine if the process is completed
}

const steps = [
    "Initializing",
    "Processing APK checks",
    "Processing tires",
    "Uploading to dealer portal",
    "Finalizing",
];

const PulsingConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: `linear-gradient(95deg,
                ${theme.palette.primary.main} 0%,
                ${theme.palette.primary.light} 50%,
                ${theme.palette.primary.main} 100%)`,
            backgroundSize: "400% 400%",
            animation: "pulse 1.5s ease-in-out infinite",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
    "@keyframes pulse": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "50%": {
            backgroundPosition: "100% 50%",
        },
        "100%": {
            backgroundPosition: "0% 50%",
        },
    },
}));

const StepIconRoot = styled("div")<{
    ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage: `linear-gradient( 136deg, ${theme.palette.success.light} 0%, ${theme.palette.success.main} 50%, ${theme.palette.success.dark} 100%)`,
    }),
}));

function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <StepIconRoot ownerState={{ active, completed }} className={className}>
            {props.icon}
        </StepIconRoot>
    );
}

const SaveProgressPopup: React.FC<SaveProgressPopupProps> = ({
    open,
    step,
    stepName,
    progress,
    error,
    alert,
    onClose,
    isCompleted,
}) => {
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (isCompleted || error) {
                    onClose();
                }
            }}
            disableEscapeKeyDown={!isCompleted && !error}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Saving Report</DialogTitle>
            <DialogContent>
                {error && (
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="error">{error}</Alert>
                    </Box>
                )}
                {alert && (
                    <Box sx={{ mb: 2 }}>
                        <Alert severity="info">{alert}</Alert>
                    </Box>
                )}
                <Box sx={{ width: "100%", mt: 2 }}>
                    <Stepper
                        activeStep={step - 1}
                        alternativeLabel
                        connector={<PulsingConnector />}
                    >
                        {steps.map((label, index) => (
                            <Step key={label} completed={index < step - 1}>
                                <StepLabel StepIconComponent={StepIcon}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Typography variant="body1">
                        Current step: {stepName}
                    </Typography>
                    {progress && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            {progress}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ width: "100%", mt: 2 }}>
                    <LinearProgress
                        variant="determinate"
                        value={(step / steps.length) * 100}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SaveProgressPopup;

import styled from "styled-components";

export const NavBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 50px;
    background-color: #fff;
`;

export const LeftSection = styled.div`
    gap: 25px;
    align-items: center;
    display: flex;
    flex-direction: row;
    float: left;
`;

export const RightSection = styled.div`
    float: right;
`;

export const Logo = styled.img`
    display: flex;
    align-items: center;
    height: 100px;
`;

// Add this to your Navigation styles (index.styles.ts in the Navigation component folder)
export const LicensePlateDisplay = styled.div`
    background-color: #f4be04;
    color: black;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 20px;
`;

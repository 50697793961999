import styled from "styled-components";

export const Wrapper = styled.div`
    margin-bottom: 1rem;
    padding: 0 0 30px 0;
`;

export const Label = styled.label`
    width: 100%;
    line-height: 1;
    display: block;
    padding: 0 1rem 1rem 0;
`;

export const Input = styled.input`
    box-sizing: border-box;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #ddd;
    color: #000;
    outline: 0;
    margin: 0;
    padding: 0;
    padding-left: 1.2rem;
    text-align: left;
    color: #8a95a3;
    height: 4rem;
    width: calc(100% - 20px);
    border-radius: 3px;
    vertical-align: top;
`;

import styled from "styled-components";

export const TranscriberInputWrapper = styled.div`
    display: flex;
    border-radius: 5px;
    background: white;
    flex: 1;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1.4rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px #ddd solid;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const RecordingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    overflow-y: auto;
    flex: 1;
    height: 100%;
    gap: 1rem;
`;

export const NotePreview = styled.div`
    border-radius: 8px;
    background-color: #f4f4f4;
`;

export const Recording = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px #ddd solid;
    padding: 1rem;
    cursor: pointer;
`;

export const RecordingHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Transcript = styled.div`
    background-color: #f4f4f4;
    text-wrap: wrap;
    font-size: 1.5rem;
    border-radius: 3px;
    padding: 0 1rem;
`;

export const EditButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    border: none;

    padding: 12px 20px;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    color: #053b80;
    display: inline-block;
    border-radius: 0;
    background: #f2f2f2;
    transition: all 0.3s ease-out;
    font-size: 1.6rem;
    line-height: 1;
    font-weight: 400;
    text-shadow: none;
    border: 1px solid #053b80;
    letter-spacing: 0.5px;
`;

import React, { useState } from "react";
import {
    HeaderWrapper,
    JobSelectionWrapper,
    LicensePlate,
    LicensePlateWrapper,
    NLTag,
    LicenseNumber,
    JobWrapper,
    JobListWrapper,
    ChecklistType,
    ExpandButton,
} from "./index.styles";
import { EmptyMessage } from "@components/EmptyMessage";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type Car = {
    jobHash: string;
    jobId: string;
    carId: string;
    jobName: string;
    carModel: string;
    licensePlate: string;
    checklistType: string;
    transcripts: string[];
};

interface JobSelectionProps {
    cars: Car[];
    selectJob: (jobHash: string) => Promise<void>;
    selectedCar: string;
}

const MAX_CHECKLIST_LENGTH = 50;

export const JobSelection: React.FC<JobSelectionProps> = ({
    cars,
    selectJob,
    selectedCar,
}) => {
    const [expandedJobs, setExpandedJobs] = useState<{
        [key: string]: boolean;
    }>({});

    const handleJobClick = (jobHash: string) => {
        selectJob(jobHash);
    };

    const toggleExpand = (jobHash: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setExpandedJobs((prev) => ({ ...prev, [jobHash]: !prev[jobHash] }));
    };

    const renderChecklistType = (checklistType: string, jobHash: string) => {
        const isExpanded = expandedJobs[jobHash];
        const types = checklistType.split("<br>");
        const truncatedTypes = isExpanded ? types : types.slice(0, 2);
        const needsTruncation = types.length > 2;

        return (
            <>
                <ChecklistType>
                    {truncatedTypes.map((type, index) => (
                        <p
                            style={{ fontSize: "14px", padding: 0 }}
                            key={index}
                        >
                            {type}
                        </p>
                    ))}
                    {!isExpanded && needsTruncation && (
                        <span style={{ opacity: "50%" }}>
                            Klik op de pijl om meer te tonen...
                        </span>
                    )}
                </ChecklistType>
                {needsTruncation && (
                    <ExpandButton onClick={(e) => toggleExpand(jobHash, e)}>
                        {isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </ExpandButton>
                )}
            </>
        );
    };

    return (
        <JobSelectionWrapper>
            <JobListWrapper>
                {cars.length > 0 ? (
                    cars.map((car) => (
                        <JobWrapper
                            key={car.jobHash}
                            onClick={() => handleJobClick(car.jobHash)}
                            style={{
                                backgroundColor:
                                    selectedCar === car.jobHash
                                        ? "#f2f2f2"
                                        : "transparent",
                            }}
                        >
                            <LicensePlateWrapper>
                                <LicensePlate>
                                    <LicenseNumber>
                                        {car.licensePlate}
                                    </LicenseNumber>
                                </LicensePlate>
                            </LicensePlateWrapper>
                            <h4>{car.carModel}</h4>
                            <h4 style={{ fontWeight: "400", opacity: 0.4 }}>
                                {car.jobName}
                            </h4>
                            {renderChecklistType(
                                car.checklistType,
                                car.jobHash
                            )}
                        </JobWrapper>
                    ))
                ) : (
                    <EmptyMessage message="Geen werkorders beschikbaar" />
                )}
            </JobListWrapper>
        </JobSelectionWrapper>
    );
};
